// Generated from: https://www.realtimecolors.com/?colors=dadcf6-080b21-276d8b-060818-29d1aa&fonts=Poppins-Inter
// (update this URL every time you update this file)

export const colors = {
  text: {
    50: '#eaecfa',
    100: '#d6d8f5',
    200: '#adb1eb',
    300: '#848ae1',
    400: '#5b63d7',
    500: '#323ccd',
    600: '#2830a4',
    700: '#1e247b',
    800: '#141852',
    900: '#0a0c29',
    950: '#050615',
  },
  background: {
    50: '#eaecfa',
    100: '#d6daf5',
    200: '#adb4eb',
    300: '#848fe1',
    400: '#5b69d7',
    500: '#3244cd',
    600: '#2836a4',
    700: '#1e297b',
    800: '#141b52',
    900: '#15191b',
    950: '#15191b',
  },
  primary: {
    50: '#eafbf7',
    100: '#d4f7ef',
    200: '#aaeede',
    300: '#7fe6ce',
    400: '#55ddbd',
    500: '#2ad5ad',
    600: '#22aa8a',
    700: '#198068',
    800: '#115545',
    900: '#082b23',
    950: '#041511',
  },
  secondary: {
    50: '#ebecfa',
    100: '#d6daf5',
    200: '#adb5eb',
    300: '#858fe0',
    400: '#5c6ad6',
    500: '#3345cc',
    600: '#2937a3',
    700: '#1f297a',
    800: '#141c52',
    900: '#0a0e29',
    950: '#050714',
  },
  accent: {
    50: '#eafbf7',
    100: '#d4f7ef',
    200: '#aaeede',
    300: '#7fe6ce',
    400: '#55ddbd',
    500: '#2ad5ad',
    600: '#22aa8a',
    700: '#198068',
    800: '#115545',
    900: '#082b23',
    950: '#041511',
  },
};
