'use client';

import Image from 'next/image';
import { colors } from '@/constants/tailwindColors';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export type Slide = {
  sm?: string;
  md?: string;
  lg?: string;
};

type SliderProps = {
  slides: Slide[];
};

export const Slider = ({ slides }: SliderProps) => (
  <Swiper
    style={{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }}
    spaceBetween={10}
    navigation={true}
    pagination={{ clickable: true }} // Add pagination bullets
    loop={true}
    autoplay={{ delay: 10000 }} // Auto swipe every 10 seconds
    modules={[FreeMode, Navigation, Pagination, Autoplay]} // Include Pagination and Autoplay modules
    className="relative -mt-40 h-[calc(100vh/2)] w-full  select-none bg-gray-200 dark:bg-background-800/10 sm:h-[calc(100vh/1.5)] lg:h-screen"
  >
    <style
      dangerouslySetInnerHTML={{
        __html: `
            .swiper-pagination {
              .swiper-pagination-bullet-active { background: ${colors.primary[500]};}
              .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) { background: ${colors.primary[500]}; }
            }
          `,
      }}
    />
    {slides.map((url, index) => (
      <SwiperSlide key={index}>
        <Image className="object-cover md:hidden" src={String(url?.sm || url?.md || url?.lg)} alt="..." fill />
        <Image className="object-cover lg:hidden lt-md:hidden" src={String(url.md || url.lg)} alt="..." fill />
        <Image className="lt-lg:hidden object-cover" src={String(url.lg)} alt="..." fill />
      </SwiperSlide>
    ))}
  </Swiper>
);
